import React from 'react';
import { ModalContextType, ModalType, useModalContext } from 'src/hooks/useModal';
import { ScrollModal } from '../../primitives/ScrollModal';
import { PositionModalWrapper } from '../FlowCommons/PositionModalWrapper';
import { ClaimFeesModalContent } from './ClaimFeesModalContent';

export const ClaimFeesModal = () => {
  const { type, args, close } = useModalContext() as ModalContextType<{
    positionAddress: string;
  }>;

  return (
    <ScrollModal open={type === ModalType.ClaimFees} setOpen={close}>
      <PositionModalWrapper
        positionAddress={args.positionAddress}
        modernStyle={true}
        title="Claim fees"
      >
        {(params) => <ClaimFeesModalContent {...params} />}
      </PositionModalWrapper>
    </ScrollModal>
  );
};
