import React from 'react';
import { Trans } from '@lingui/macro';

import { useModalContext } from '../../../hooks/useModal';
import { useFlashLenderFeeQuery } from '../../../hooks/yldr/useFlashLenderFeeQuery';
import { PositionModalWrapperProps } from '../FlowCommons/PositionModalWrapper';
import { ScrollModalContent, ScrollModalFooter } from '../../primitives/ScrollModal';
import { DetailsNumberLine, TxModalDetails } from '../FlowCommons/TxModalDetails';
import { TxSuccessView } from '../FlowCommons/Success';
import { ClaimFeesActions } from './ClaimFeesActions';
import { TxPositionFeesDetails } from '../FlowCommons/TxPositionFeesDetails';
import { SLIPPAGE_WARNING } from '../constants';
import { AlertBanner } from '../../../ui-kit/AlertBanner';

export const ClaimFeesModalContent = React.memo(
  ({ enrichedPosition, positionAddress, isWrongNetwork }: PositionModalWrapperProps) => {
    const { gasLimit, txError, mainTxState, setTxError } = useModalContext();
    const { data: compoundCost, isLoading } = useFlashLenderFeeQuery({
      debtToken: enrichedPosition.debtToken,
      amount: enrichedPosition.debtWithoutDecimals,
    });

    const feesCollected = enrichedPosition.token0.marketReferenceCurrencyFee + enrichedPosition.token1.marketReferenceCurrencyFee

    const deleveragePositionActionsProps = {
      marketId: enrichedPosition.marketId,
      isWrongNetwork,
      positionAddress,
      feesCollected,
      noFees: feesCollected <= 0,
      disabled: enrichedPosition.lowHealthFactor,
    }

    if (mainTxState.success) {
      return (
        <ScrollModalContent>
          <TxSuccessView
            action={<Trans>successfully claimed</Trans>}
            prefix="$"
            amount={mainTxState.value}
          />
        </ScrollModalContent>
      );
    }

    return (
      <>
        <ScrollModalContent>
          <TxPositionFeesDetails
            token0={enrichedPosition.token0}
            token1={enrichedPosition.token1}
          />
          <TxModalDetails
            description={SLIPPAGE_WARNING}
            gasLimit={gasLimit}
            skipLoad={true}
          >
            <DetailsNumberLine
              description="Total fees earned"
              value={feesCollected}
              numberPrefix='$'
            />
            <DetailsNumberLine
              description="Transaction fees"
              value={compoundCost}
              numberPrefix="$"
              loading={isLoading}
            />
            <DetailsNumberLine
              description="Total fees claimed"
              value={feesCollected}
              numberPrefix="$"
              loading={isLoading}
            />
          </TxModalDetails>

          {enrichedPosition.lowHealthFactor && (
            <AlertBanner
              variant="warning"
              message="Transaction is unavailable due to the low Heath Factor. Please close this position and open a new one."
            />
          )}

          {txError && (
            <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
          )}

        </ScrollModalContent>

        <ScrollModalFooter>
          <ClaimFeesActions {...deleveragePositionActionsProps} />
        </ScrollModalFooter>
      </>
    );
  }
);
